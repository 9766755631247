import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Link } from 'gatsby'
import React from 'react'
import Meta from 'components/meta/meta'
import Layout from 'components/layout/layout'
import styled from 'styled-components'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import BackgroundImage from 'gatsby-background-image'
import ContactForm from 'components/contact-form'
import { Helmet } from 'react-helmet'
import OGImage from '../../../content/images/logo.png'

interface Props {
  location: Location
}

const InsurancePage: React.FC<Props> = ({ data, location }: Props) => {
  const meta = data.site?.meta
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_img = data.hero_img?.childImageSharp?.fluid
  const section2_background = data.section2_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
      <Helmet>
        <link rel="canonical" href="https://meridianendoindy.com/patient-information/insurance/"/>
        <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
        <title>Insurance Information endodontics pc, Indianapolis IN</title>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Insurance Information endodontics pc, Indianapolis IN"/>
        <meta name="description" content="Dental Insurance Information for Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="og:description" content="Dental Insurance Information for Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta property="og:url" content="https://meridianendoindy.com/patient-information/insurance/"/>
        <meta property="og:image" content={OGImage}/>
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
        <meta name="twitter:url" content="https://meridianendoindy.com/patient-information/insurance/" />
        <meta name="twitter:card" content="summary"/>
        <meta name="twitter:title" content="Insurance Information endodontics pc, Indianapolis IN"/>
        <meta name="twitter:description" content="Dental Insurance Information for Indianapolis IN Endodontist Dr. Broady. 317-846-4980."/>
        <meta name="twitter:image" content={OGImage} />
        <meta property="og:image:width" content="279"/>
        <meta property="og:image:height" content="65"/>
      </Helmet>
      <BackgroundImage
        Tag="section"
        className="insurance-hero-section-bg hero-bg"
        fluid={hero_background}
        alt="hero background image"
      >
        <div id="insurance-hero-section">
          <Container>
            <div className="col-lg-6 left-content pb-5">
              <h1 className="primary-text hero-heading">
                Insurance Information
              </h1>
              <p className="hero-text">
                At Meridian Endodontics we make every effort to provide you with the finest care and the most convenient financial options.
              </p>
              <Link to="/contact-us/contact-information-office-map"><button className="hero-section-btn"> Schedule a Consultation</button></Link>
            </div>
            <div className="col-lg-6">
              <Img fluid={hero_img} className="hero-img" alt="man holding jacket at his back and smiling"/>
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id="faded-bg"></section>
      <section id="insurance-section-1">
        <Container>
          <div className="col-lg-12 container-box">
            <p>
              At Meridian Endodontics, we work hand-in-hand with you and your coverage provider to maximize your insurance reimbursement for covered procedures. Additionally, along with the finest care, we provide a wide range of convenient financial options. If you have any problems or questions, please ask our staff. They are well informed and up-to-date, so they can offer the best advice on maximizing coverage. To expedite insurance payments, bring your coverage provider's information to the consultation.
            </p>
           <p className="mb-0">
             For billing inquiries or concerns about your first visit, contact us at <a href="tel:3178464980" id="tel-link">317-846-4980.</a>
            </p>
          </div>
        </Container>
      </section>
      <BackgroundImage
        Tag="section"
        className="insurance-section2-bg"
        fluid={section2_background}
        alt="insurance Background Image"
      >
        <section id="insurance-section-2">
          <ContactForm />
        </section>
      </BackgroundImage>
    </Layout>
  )
}

export default InsurancePage

export const query = graphql`
  query InsurancePageQuery {
    site {
      meta: siteMetadata {
        title
        description
        siteUrl
      }
    },
    hero_background: file(name: { eq: "introduction-hero-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_img: file(name: { eq: "insurance-hero-img" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section2_background: file(name: { eq: "insurance-section2-bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    },
  }
`
